import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";
import ExecutiveList from "../../components/executive-committee-list";

import WithService from "../../service/withService";
import { useSearchProfile } from "../../hooks/useSearchProfile";
import { SearchProfile } from "../../components/search-profiles";

const ExecutiveCommitteeList = ({ loading, response, error }) => {
  const talonProps = useSearchProfile({
    data: response?.people,
  });

  const { filteredData, filter, data, searchLength } = talonProps;

  return (
    <>
      {data?.length > 0 && (
        <SearchProfile
          onChange={filter}
          actualData={data}
          searchLength={searchLength}
        />
      )}
      <div className="row mb-4">
        <ExecutiveList
          loading={loading}
          response={filteredData}
          isProfileImage
        />
      </div>
    </>
  );
};

const ExecutiveCommittee = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Executive Committee" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">Executive Committee</h3>
              <WithService endpoint="people-by-head/people-executive-committee">
                <ExecutiveCommitteeList />
              </WithService>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ExecutiveCommittee;
