import React from "react";
import Shimmer from "react-shimmer-effect";
import { Link } from "gatsby";

const ExecutiveList = ({ loading, response, error, isProfileImage = true }) => {
  if (loading) {
    return (
      <Shimmer>
        <div className="about-profile-list-shimmer"></div>
      </Shimmer>
    );
  }
  if (response.length > 0) {
    return response.map(item => {
      return (
        <div className="col-12 col-md-4 col-lg-4 col-xl-3 mb-4" key={item.id}>
          <div className="about-profile-list h-100">
            {isProfileImage && (
              <Link
                className="about-profile-list__img-wrapper"
                to={`/people/${item?.uuid}`}
              >
                <img
                  src={item?.image_url}
                  className="w-100"
                  alt={item?.name}
                ></img>
              </Link>
            )}
            <div className="about-profile-list__content p-4">
              <span className="about-profile-list__content-category">
                {item?.pivot?.designation}
              </span>
              <Link
                to={`/people/${item?.uuid}`}
                className="about-profile-list__content-title"
              >
                {item?.name}
              </Link>
              <p className="about-profile-list__content-address">
                {item?.address}
              </p>
              {item?.email_field && (
                <span className="d-flex align-items-center">
                  <span className="icon ic-email me-2"></span>
                  {item?.email_field?.split(",").map((email, index, row) => {
                    return (
                      <>
                        <a
                          className="about-profile-list__content-email"
                          href={`mailto:${email}`}
                          key={email}
                        >
                          {email}
                        </a>
                        {index + 1 === row.length ? "" : ","}
                        &nbsp;
                      </>
                    );
                  })}
                </span>
              )}
              {item?.mobile_field && (
                <span className="d-flex align-items-center">
                  <span className="icon ic-mobile me-2"></span>
                  {item?.mobile_field?.split(",").map((mob, index, row) => {
                    return (
                      <>
                        <a
                          className="about-profile-list__content-mobile"
                          href={`tel:${mob}`}
                          key={mob}
                        >
                          {mob}
                        </a>
                        {index + 1 === row.length ? "" : ","}
                        &nbsp;
                      </>
                    );
                  })}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    });
  }
  return null;
};

export default ExecutiveList;
